require('./bootstrap');
import { InertiaApp } from "@inertiajs/inertia-vue"
import Vue from 'vue'
import inertiaTitle from 'inertia-title/vue2'

Vue.mixin({
    methods: {
        route: window.route
    }
})

Vue.use(inertiaTitle)

let app = document.getElementById('app');

new Vue({
    metaInfo: {
				titleTemplate: 'Inertia: %s'
    },
    render: h => h(InertiaApp, {
        props: {
            initialPage: JSON.parse(app.dataset.page),
            resolveComponent: name => import(`./Pages/${name}`).then(module => module.default),
        },
    }),
}).$mount(app)

